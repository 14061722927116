.App {
  text-align: center;
  display: grid;
  width: 100%;
  margin: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* --------- Typography ---------- */

h1 {
  font-family: Outfit;
  font-weight: 100;
  color: #ffffff;
  font-size: 40px;
  padding-top: 0px;
  padding-bottom: 30px;
}

h2 {
  font-family: Outfit;
  font-size: 40px;
  font-weight: 100;
}

h3 {
  font-family: Outfit;
  font-weight: 400;
}

h4 {
  font-family: Outfit;
  font-weight: 300;
}

h5 {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  line-height: 170%;
}

p {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 300;
}

ul {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 300;
}

li {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 300;
}

/* --------- Button ---------- */

.btn-primary {
  background-color: #C47F43;
  -webkit-background-color: #C47F43;
  border: none;
  border-radius: 0;
  font-family: Outfit;
  font-weight: 300;
  letter-spacing: 2px;
}

.btn-primary:hover {
  background-color: #BA7643;
  -webkit-background-color: #BA7643;
  font-weight: 300;
}

.btn-primary:active {
  background-color: #BA7643;
  font-weight: 300;
}

.btn-primary:focus {
  background-color: #BA7643;
  font-weight: 300;
  box-shadow: 0 0 0 0.25rem #E0B394;
}

.btn-primary:active:focus {
  background-color: #BA7643;
  font-weight: 300;
  box-shadow: 0 0 0 0.25rem #E0B394;
}

/* --------- NavBar ---------- */
.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  font-family: Outfit;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 20px 20px;
  z-index: 99 !important;
}

.logo {
  text-decoration: none;
  font-family: Sacramento;
  font-size: 36px;
  color: #000000;
  margin: 0 10px;
}

.logo:hover {
  color: #000000;
}

.nav-item {
  margin: 0 10px;
}

.dropdown-item {
  font-family: Outfit;
  font-size: 15px;
  letter-spacing: normal;
  color: #495057;
}


/* --------- Page 1 - Home Page ---------- */

.homepage {
  display: block;
}

.hero-banner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}

.hero-text-banner {
  height: 1000px;
  width: 36%;
  background-color: #C47F43;
  margin: 0;
  padding: 100px, 100px;
  text-align: left;
  position: relative;
  order: 1;
}

.hero-image-container {
  width: 64%;
  height: 1000px;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  order: 2;
}

.hero-text-container {
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 950px) {
  .hero-text-banner {
    height: 400px;
    width: 100%;
    padding: 100px, 100px;
    order: 2;
  }

  .hero-text-container {
    width: 80%;
  }

  .hero-image-container {
    width: 100%;
    height: 600px;
    order: 1;
  }

  .hero-image {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 700px) {
  .hero-text-banner {
    height: 550px;
    width: 100%;
    order: 2;
  }

  .hero-text-container {
    width: 80%;
  }
}

.popular-pick {
  padding: 80px 60px;
}

.product-item {
  padding-top: 50px;
  padding-left: 0;
  padding-right: 0;
}

.product-image {
  width: 100%;
}

@media (max-width: 800px) {
  .popular-pick {
    padding: 80px 40px;
    margin: auto;
  }

  .product-image {
    width: 100%;
  }
}

.item-name {
  font: Outfit;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 8px;
}

.item-name-link {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #1a1a1a;
}

.item-name-link:hover {
  color: #C47F43;
  font-weight: 600;
}

.item-name-link:focus {
  color: #BA7643;
  font-weight: 600;
}

.item-category {
  font: Outfit;
  font-weight: 500;
  color: #8f8f8f;
}

.product-category-even {
  padding: 20px 50px 5px 50px;
}

.product-category {
  padding: 20px 50px 5px 50px;
}

.category-image-container-odd {
  width: 50%;
  height: 500px;
  margin-top: 0;
  padding-left: 16px;
  display: inline-block;
  vertical-align: top;
}

.category-image-container-even {
  width: 50%;
  height: 500px;
  margin-top: 0;
  padding-right: 16px;
  display: inline-block;
  vertical-align: top;
}

.category-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.category-info-container-even {
  width: 50%;
  height: 500px;
  padding-left: 16px;
  display: inline-block;
}

.category-info-container-odd {
  width: 50%;
  height: 500px;
  padding-right: 16px;
  display: inline-block;
}

.category-info {
  height: 100%;
  background-color: #E3E3E3;
  margin: auto;
  position: relative;
}

.category-info-inner {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 800px) {
  .product-category {
    width: 100%;
    padding: 20px 30px;
    margin-bottom: 10px;
    display: block;
    display: flex;
    flex-wrap: wrap;
  }

  .category-image-container-even {
    width: 100%;
    height: 350px;
    display: block;
    padding-right: 0;
  }

  .category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .category-info-container-even {
    width: 100%;
    height: 380px;
    display: block;
    padding: 0;
  }

  .category-image-container-odd {
    width: 100%;
    height: 350px;
    display: block;
    padding-right: 0;
    padding-left: 0;
    order: 1;
  }

  .category-info-container-odd {
    width: 100%;
    height: 380px;
    display: block;
    padding: 0;
    order: 2;
  }
}

.category-description {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.shop-by-categories-button {
  background-color: #C47F43;
  border: none;
  border-radius: 0;
  font-family: Outfit;
  font-weight: 300;
  letter-spacing: 2px;
  padding: 10px 40px;
  margin-top: 10px;
}

.view-all-product-button {
  width: 100%;
  padding: 10px 20px;
  margin-top: 60px;
  background-color: #BA7643;
}

.footer {
  bottom: 0px;
  width: 100%;
  margin-top: 80px;
  position: relative;
}

.footer-info-container {
  width: 100%;
  padding: 40px 0px;
  background-color: #C47F43;
  margin: 0px;
}

.footer-info {
  width: 100%;
}

.company-info {
  width: 70%;
  text-align: left;
}

.logo-footer {
  font-family: Sacramento;
  font-size: 36px;
  color: #ffffff;
  margin-right: 50px;
  margin-left: 30px;
}

.company-brief {
  color: #ffffff;
  margin-right: 50px;
  margin-left: 30px;
}

.footer-nav {
  width: 30%;
  text-align: left;
  padding-left: 60px;
  padding-right: 0;
}

.footer-nav-item {
  color: #ffffff;
  text-decoration: none;
}

.footer-nav-item:hover {
  color: #ffffff;
  font-weight: 600;
}

.footer-nav-item:focus {
  color: #E0B394;
  font-weight: 600;
}

.copyright-social-media {
  width: 100%;
  height: 70px;
  background-color: #BA7643;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
}

.copyright-container {
  width: 55%;
  text-align: left;
  padding-left: 50px;
  padding-right: 0px;
}

.copyright {
  color: white;
  margin: 0;
}

.social-media-container {
  width: 45%;
  text-align: right;
  padding-right: 30px;
}

@media (max-width: 900px) {
  
  .footer-nav {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .company-info {
    width: 100%;
    padding-left: 10px;
    margin-top: 30px;
  }

  .logo-footer {
    margin-right: 30px;
  }

  .company-brief {
    margin-right: 30px;
  }

  .copyright-social-media {
    width: 100%;
    padding-left: 0px;
  }

  .copyright-container {
    padding-left: 30px;
    padding-right: 0px;
  }

  .social-media-container {
    padding-right: 10px;
  }
}

@media (max-width: 700px) {
  .copyright-social-media {
    width: 100%;
    height: 120px;
    padding-left: 0;
  }

  .copyright-container {
    width: 100%;
    order: 2;
    text-align: center;
    margin-bottom: 22px;
    padding-left: 0px;
  }

  .social-media-container {
    width: 100%;
    order: 1;
    text-align: center;
    margin-top: 22px;
  }
}

/* --------- Page 2 - All Products ---------- */

.all-products {
  padding: 80px 60px 0px;
}

.contemporary-collection {
  padding: 80px 60px 0px;
  min-height: 800px;
}

.heritage-collection {
  padding: 80px 60px 0px;
  min-height: 800px;
}

@media (max-width: 800px) {
  .all-products {
    padding: 80px 40px 0px;
  }
  
  .contemporary-collection {
    padding: 80px 40px 0px;
  }
  
  .heritage-collection {
    padding: 80px 40px 0px;
  }
}

/* --------- Page 3 - About Us ---------- */

.about-us {
  width: 100%;
  padding: 80px 50px 30px 50px;
}

.about-us-page-title-container {
  width: 100%;
  height: 120px;
  border: 4px #BA7643;
  border-style: dotted;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-us-page-title {
  color: #000000;
}

.about-us-content {
  width: 100%;
  margin: 0;
  padding: 80px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-text-container {
  width: 50%;
  height: 600px;
  background-color: #BA7643;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-text-container-inner {
  width: 100%;
  top: 50%;
  background-color: #BA7643;
  text-align: left;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-text-title {
  width: 100%;
  font-size: 30px;
  color: #ffffff;
  text-align: left;
  margin-bottom: 30px;
}

.about-text {
  color: #ffffff;
}

.about-image-container {
  width: 50%;
  height: 600px;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.about-us-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 900px) {
  .about-us {
    padding: 40px 30px 0px 30px;
  }

  .about-us-content {
    padding: 40px 0px 0px 0px;
  }
  .about-text-container {
    width: 100%;
    height: 320px;
  }

  .about-image-container {
    width: 100%;
    height: 320px;
  }

  .about-text-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .about-text-title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .about-text-container-inner {
    padding: 20px;
  }

  .about-text-container {
    height: 500px;
  }
}

/* --------- Page 4 - Product Details ---------- */

.product-details {
  width: 100%;
  margin: 0px;
  padding: 80px 50px 0px 50px;
}

.carousel-container {
  width: 60%;
  padding: 0;
  max-width: 900px;
}

.product-details-infos-container {
  width: 40%;
  padding: 0;
  padding-left: 50px;
}

.carousel-control-prev {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #b6b6b6;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-next {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #b6b6b6;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}

.product-name {
  font-family: Outfit;
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
}

.product-collection-artist {
  font-weight: 400;
}

.product-infos {
  text-align: left;
  max-width: 600px;
}

@media (max-width: 1000px) {
  .product-details {
    padding: 40px 50px 40px 50px;
  }

  .carousel-container {
    width: 100%;
    margin-bottom: 40px;
  }

  .product-details-infos-container {
    width: 100%;
    padding: 0px;
  }

  .product-infos {
    max-width: 1000px;
  }
}

@media (max-width: 900px) {
  .product-details {
    padding: 40px 30px 40px 30px;
  }

  .product-infos {
    max-width: 900px;
  }
}

.pricing-value {
  font-size: 24px;
  margin: 5px 0 20px;
}

.select-image-size-button {
  margin-bottom: 20px;
}

.size-selection-button {
  font-family: Outfit;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 0;
  border-color: #282c34;
  color: #282c34;
  background-color: white;
  width: 90px;
  padding: 6px 12px;
  margin-right: 10px;
}

.size-selection-button:hover {
  color: white;
  border-color: #C47F43;
  background-color: #C47F43;
}

.btn-check:checked+.size-selection-button {
  font-family: Outfit;
  font-size: 16px;
  color: white;
  border-radius: 0;
  border-color: #282c34;
  background-color: #282c34;
  width: 90px;
  padding: 6px 12px;
  margin-right: 10px;
}

.btn-check:checked:focus+.size-selection-button {
  font-family: Outfit;
  font-size: 16px;
  color: white;
  border-radius: 0;
  border-color: #282c34;
  background-color: #282c34;
  width: 90px;
  padding: 6px 12px;
  margin-right: 10px;
  box-shadow: 0 0 0 0.25rem #E0B394;
}

.product-detail-quantity-selection {
  width: 110px;
  margin-top: 10px;
}

.adjust-quantity-button {
  width: 110px;
  padding: 0;
  margin: auto;
}

.quantity-minus-button {
  color: #000000;
  margin: 0 auto 0 0;
}

.quantity-plus-button {
  color: #000000;
  margin: 0 0 0 auto;
}

.quantity-trash-button {
  color: #000000;
}

.quantity-trash-button-icon {
  color: #000000;
}

.quantity-field {
  width: 40px;
  font-family: Outfit;
  text-align: center;
  margin: auto;
}

.add-to-cart-button {
  width: 100%;
  background-color: #C47F43;
  border: none;
  border-radius: 0;
  font-family: Outfit;
  font-weight: 300;
  letter-spacing: 2px;
  padding: 10px 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.product-description {
  padding-top: 0;
}

.add-to-cart-with-quantity {
  display: flex;
}

.icon-shape {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}

.icon-sm {
  width: 2rem;
  height: 2rem;
}

.border-product {
  margin: 0 0 25px;
  border-top: 1px solid #efefef;
}


/* --------- Page 5 - Shopping Cart ---------- */

.cart {
  width: 100%;
  padding: 80px 40px 0px 40px;
  margin: auto;
  font-family: Outfit;
}

.cart-table {
  margin: 80px 15px 0px 15px;
}

.cart-table-heading {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 15px;
}

.cart-table-heading-product {
  text-align: left;
}

.cart-table-heading-quantity {
  text-align: center;
}

.cart-table-heading-total {
  text-align: right;
}

.cart-table-row {
  margin-left: 0;
  padding-left: 0;
  padding-bottom: 25px;
}

.cart-item-info {
  padding: 0;
  margin: 0;
}

.cart-item-info-container {
  display: flex;
  padding: 0;
  margin: 0;
}

.cart-table-product-image-container {
  width: 30%;
  max-width: 150px;
  height: 150px;
  display: flex;
  padding-left: 0;
}

.cart-table-product-image {
  height: 150px;
  padding-left: 0;
}

.cart-table-product-info {
  width: 70%;
  text-align: left;
  margin: auto 0px;
  padding-left: 25px;
}

@media (max-width: 900px) {
  .cart {
    padding: 80px 15px;
  }

  .cart-table-product-image-container {
    margin-top: 0;
    display: flex;
  }

  .cart-table-product-info {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 15px;
  }
}

.cart-table-product-name {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}

.cart-table-product-size {
  font-size: 16px;
  font-weight: 400;
  color: #848484;
  margin-bottom: 5px;
}

.cart-table-product-pricing {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
}

.cart-adjust-quantity-button {
  margin: auto;
}

.cart-item-total-pricing {
  font-size: 16px;
  font-weight: 300;
  text-align: right;
  margin: auto;
}

@media (max-width: 480px) {
  .cart-item-info {
    width: 40%;
  }

  .cart-adjust-quantity-button {
    width: 35%;
  }

  .cart-item-total-pricing {
    width: 25%;
  }
}

.cart-table-footer {
  text-align: right;
}

.cart-subtotal {
  font-size: 17px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 5px;
}

.cart-taxes-shipping-reminder {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  color: #848484;
  margin-bottom: 25px;
}

.empty-cart-image-container {
  width: 70%;
  margin: 0px auto;
}

.check-out-button {
  width: 200px;
}